import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_investment_type_form_jiulei',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'budgetSubjectsName') {
        v.restful = '/tpm/tpmBudgetSubjectsController/list';
        v.optionsKey = { label: 'budgetSubjectsName', value: 'budgetSubjectsName' };
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'budgetSubjectsName',
        };
        v.restfulParams = { isActBudget: 'Y', enableStatus: '009' };
      } else if (v.field === 'financeSubjectName') {
        v.restful = '/tpm/tpmFinanceSubjectController/list';
        v.optionsKey = { label: 'financeSubjectName', value: 'financeSubjectName' };
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'financeSubjectName',
        };
      } else if (v.field === 'ownedCusCode') {
        v.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        v.optionsKey = { label: 'customerName', value: 'customerCode' };
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'customerName',
        };
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const budgetSubjects = this.getRule('budgetSubjectsName');

      budgetSubjects.on.change = (e) => {
        const item = budgetSubjects.options.filter((v) => v.budgetSubjectsName === e);
        this.setValue({
          budgetSubjectsCode: item[0].budgetSubjectsCode,
        });
      };

      const financeSubjectName = this.getRule('financeSubjectName');

      financeSubjectName.on.change = (e) => {
        const item = financeSubjectName.options.filter((v) => v.financeSubjectName === e);
        this.setValue({
          financeSubjectCode: item[0].financeSubjectCode,
        });
      };

      const ownedCusCode = this.getRule('ownedCusCode');

      ownedCusCode.on.change = (e) => {
        const item = ownedCusCode.options.filter((v) => v.customerCode === e);
        this.setValue({
          ownedCusName: item[0].customerName,
        });
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmCostTypeCategoriesController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              data.payType = JSON.parse(data.payType);
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmCostTypeCategoriesController/save';
        const params = { ...formData };

        params.tpmProjectName = 'liqueurTpm';
        params.payType = JSON.stringify(params.payType);

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmCostTypeCategoriesController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
